import { render, staticRenderFns } from "./Standards.vue?vue&type=template&id=f57cb63e&scoped=true"
import script from "./Standards.vue?vue&type=script&lang=js"
export * from "./Standards.vue?vue&type=script&lang=js"
import style0 from "./Standards.vue?vue&type=style&index=0&id=f57cb63e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f57cb63e",
  null
  
)

export default component.exports